<template>
  <!-- <div class="withdrawRewards">
    <div>
      <dl class="ul">
        <dd>
          <div class="id">test id</div>
          <div class="game">test gamename</div>
          <div class="time">2023-11-1</div>
          <div class="income">
            <span class="ico">test val</span>
          </div>
        </dd>
      </dl>
    </div>
    <ul>
      <li>
        <div class="info">
          <span><i>ID</i> 123</span>
          <span class="time">2023-11-11</span>
        </div>
        <div class="data">
          <span><i>{{ langTxt[lang].recommend.rewards[1] }}</i></span><span class="ico">123</span>
        </div>
        <div class="data">
          <span><i>{{ langTxt[lang].recommend.rewards[3] }}</i></span><span class="ico">123</span>
        </div>
      </li>
    </ul>
  </div> -->



  <div class="withdrawRewards">
    <div v-if="rewardList.length == 0 && offset == 0">
      <p class="null">There are no rewards to receive</p>
    </div>
    <div v-else>
      <!-- <div v-if="rewardAsset" class="assets">
        <div>Claimable Rewards: {{ rewardAsset }}</div>
        <div>Cleared After 7days <span>RECEIVE</span></div>
      </div> -->
      <dl class="ul onlypc">
        <dt>
          <div class="id">{{ langTxt[lang].recommend.rewards[0] }}</div>
          <div class="game">{{ langTxt[lang].recommend.rewards[1] }}</div>
          <div class="time">{{ langTxt[lang].recommend.rewards[2] }}</div>
          <div class="income">{{ langTxt[lang].recommend.rewards[3] }}</div>
        </dt>
        <dd v-for="(item, inx) in rewardList" :key="inx">
          <div class="id">{{ item.from_uinfo.uid }}</div>
          <div class="game">{{ formatGameName(item.game_id) }}</div>
          <div class="time">{{ formatDateTime(item.add_time) }}</div>
          <div class="income">
            <span class="ico">{{ formatAssetSilver(item.reward.asset_infos) }}</span>
          </div>
        </dd>
      </dl>
      <ul class="onlymobile">
        <li>
          <div class="info">
            <span><i>ID</i> 123</span>
            <span class="time">2023-11-11</span>
          </div>
          <div class="data">
            <span><i>{{ langTxt[lang].recommend.rewards[1] }}</i></span><span class="ico">123</span>
          </div>
          <div class="data">
            <span><i>{{ langTxt[lang].recommend.rewards[3] }}</i></span><span class="ico">123</span>
          </div>
        </li>
      </ul>
      <ui_page_btn :dataLength="rewardList.length" :count="count" :offset="offset" @turnPage="getShareRewards">
      </ui_page_btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import common from "../../utils/common";
import ui_page_btn from "../../components/ui/ui_page_btn.vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "withdrawRewards",
  props: {},
  components: { ui_page_btn },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    let offset = ref(0);
    const count = 20;

    // 获取用户分享奖励统计信息
    // /misc_api/share_rewards_statist
    let rewardAsset = ref(null);
    const getShareRewardsStatist = () => {
      const sendUrl =
        common.userApi.requestUrl + "/misc_api/share_rewards_statist";
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getShareRewardsStatist");
      axios
        .get(sendUrl, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb', r);
          if (r.data.code == 0) {
            const d = r.data.reward.asset_infos;
            for (let i = 0; i < d.length; i++) {
              if ((d[i].type = 3)) {
                rewardAsset.value = d[i].value;
                return;
              }
            }
          } else {
            toastr["erro"](r.data.msg);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          common.hideLoading("getShareRewardsStatist");
        });
    };
    getShareRewardsStatist();

    // 获取用户用户分享奖励
    // /misc_api/share_rewards[?offset=xx&count=xx&status=xx]
    // status 要过滤的状态, 0为未领取, 1为已领取, 如果不填默认为0
    let rewardList = ref([]);
    const getShareRewards = () => {
      const sendUrl =
        common.userApi.requestUrl +
        "/misc_api/share_rewards?offset=" +
        offset.value * count +
        "&count=" +
        count +
        "&status=0";
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getShareRewards");
      axios
        .get(sendUrl, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb', r);
          if (r.data.code == 0) {
            rewardList.value = r.data.infos;

            /* rewardList.value = [
              {
                add_time: 1654840478,
                from_uinfo: {
                  head_url:
                    "http://files.wargamedev.com/new_game/user_logos/000.jpg",
                  level: 1,
                  nick: "user_94005378",
                  uid: "33341430",
                },
                game_id: 10011,
                reward: {
                  asset_infos: [
                    {
                      type: 2,
                      value: 88,
                    },
                    {
                      type: 3,
                      value: 0,
                    },
                  ],
                },
              },
            ]; */
          } else {
            toastr["error"](r.data.msg);
          }
        })
        .catch((e) => {
          console.erro(e);
          toastr["error"]("e");
        })
        .finally(() => {
          common.hideLoading("getShareRewards");
        });
    };
    getShareRewards();

    //格式化时间
    const formatDateTime = (t) => {
      return common.formatDateTime(t);
    };
    //格式化游戏名称
    const formatGameName = (gameID) => {
      return common.formatGameName(gameID);
    };
    //筛选金币
    const formatAssetSilver = (asset_infos) => {
      return common.formatAssetSilver(asset_infos);
    };

    // 领取分享奖励
    // /misc_api/share_rewards/exchange_actions

    // 获取用户分享奖励的领取信息
    // /misc_api/share_reward_take_infos[?offset=xx&count=xx]

    return {
      langTxt,
      lang,
      offset,
      rewardAsset,
      rewardList,
      getShareRewards,
      formatDateTime,
      formatAssetSilver,
      formatGameName,
    };
  },
});
</script>
