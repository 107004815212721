<template>
  <div class="channelReward">
    <div class="null" v-if="channelSharingStatist.length == 0">
      <p>No record of reward selection</p>
    </div>
    <div v-else>
      <dl class="ul">
        <dt>
          <span>{{ langTxt[lang].recommend.channel[0]
          }}</span><span>{{ langTxt[lang].recommend.channel[1] }}</span><span>{{ langTxt[lang].recommend.channel[2]
            }}</span>
          <span></span>
        </dt>
        <dd v-for="(item, inx) in channelSharingStatist" :key="inx">
          <span class="ico">{{
          item && typeof item.used_jifen == "number" ? item.used_jifen : "-"
          }}</span>
          <span class="ico">{{
          item && typeof item.total_jifen == "number" ? item.total_jifen : "-"
          }}</span>
          <span class="ico">{{
          item && typeof item.vail_jifen == "number" ? item.vail_jifen : "-"
          }}</span>
          <span>
            <Ui_btn_1 v-if="item && item.vail_jifen > 0" @click="receiveChannelAward(inx)" :width="'1.67rem'">receive
            </Ui_btn_1>
            <Ui_btn_1 v-else :width="'1.67rem'" :disclick="true">{{ langTxt[lang].recommend.channel[3] }}</Ui_btn_1>
          </span>
        </dd>
      </dl>
    </div>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import common from "../../utils/common";
import Ui_btn_1 from "../ui/ui_btn_1.vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "channelReward",
  props: {},
  components: { Ui_btn_1 },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    // 如果需要查询总用户数：/user_api/my_distributor_info
    // 领取用户关联的渠道分成
    const receiveChannelAward = (t) => {
      const sendType = t + 1;
      const sendUrl = common.userApi.requestUrl + "/misc_api/channel_sharing";
      const sendHeaders = common.buildHeaders("form");
      const sendData = common.buildSendData({ type: sendType });
      common.showLoading("receiveChannelAward");
      axios
        .put(sendUrl, sendData, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb', r);
          if (r.data.code == 0) {
          } else {
            toastr["error"](r.data.msg);
          }
        })
        .catch((e) => {
          toastr["error"](e);
        })
        .finally(() => {
          common.hideLoading("receiveChannelAward");
          getChannelSharingStatist(sendType);
        });
    };
    // 渠道分享统计。type 1为交易分成统计，2为下注分成统计，3为系统收益分成统计
    // /misc_api/channel_sharing_statist?type=xx
    let channelSharingStatist = ref([]);
    const getChannelSharingStatist = (type) => {
      const sendUrl =
        common.userApi.requestUrl +
        "/misc_api/channel_sharing_statist?type=" +
        type;
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getChannelSharingStatist");
      axios
        .get(sendUrl, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb', r);
          if (r.data.code != 0) {
            // toastr["error"](r.data.msg);
          } else {
            const typeInx = type - 1;
            channelSharingStatist.value[typeInx] = r.data.info;
          }
        })
        .catch((e) => {
          toastr["error"](e);
        })
        .finally(() => {
          common.hideLoading("getChannelSharingStatist");
        });
    };
    getChannelSharingStatist(1);
    getChannelSharingStatist(2);
    getChannelSharingStatist(3);

    return {
      langTxt,
      lang,
      getChannelSharingStatist,
      channelSharingStatist,
      receiveChannelAward,
    };
  },
});
</script>
