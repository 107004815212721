<template>
  <div class="my_team template">
    <div v-if="memberList.length == 0 && offset == 0">
      <p class="null">My number of players is 0</p>
    </div>
    <div v-else>
      <dl class="ul onlypc">
        <dt>
          <span>{{ langTxt[lang].recommend.myTeam[0]
          }}</span><span>{{ langTxt[lang].recommend.myTeam[1] }}</span><span>{{ langTxt[lang].recommend.myTeam[2]
}}</span><span>{{
  langTxt[lang].recommend.myTeam[3]
}}</span><span>{{ langTxt[lang].recommend.myTeam[4] }}</span><span>{{ langTxt[lang].recommend.myTeam[5]
}}</span>
        </dt>
        <dd v-for="(item, inx) in memberList" :key="inx">
          <span>ID: {{ item.uinfo.uid }}</span><span class="ico silver">{{
            item.directly_under_reward.asset_infos[0].type == assetType
            ? item.directly_under_reward.asset_infos[0].value
            : item.directly_under_reward.asset_infos[1].value
          }}</span><span class="ico silver">{{
  item.total_reward.asset_infos[0].type == assetType
  ? item.total_reward.asset_infos[0].value
  : item.total_reward.asset_infos[1].value
}}</span><span>{{ item.directly_under_count }}</span><span>{{ item.total_count }}</span><span class="mortina"
            v-if="item.directly_under_count == 0 && item.total_count == 0">{{ langTxt[lang].recommend.myTeam[6] }}</span>
          <span class="mortina agent" v-else v-html="'Commision<br>Based Agent'"></span>
        </dd>
      </dl>
      <ul class="onlymobile">
        <li v-for="(item, inx) in memberList" :key="inx">
          <div class="info">
            <span><i>ID </i> {{ item.uinfo.uid }}</span><span><i>{{ langTxt[lang].recommend.myTeam[4] }} </i> {{ item.total_count }}</span>
          </div>
          <div class="data">
            <i>{{ langTxt[lang].recommend.myTeam[1] }}</i>
            <span class="ico silver">
              {{
                item.directly_under_reward.asset_infos[0].type == assetType
                ? item.directly_under_reward.asset_infos[0].value
                : item.directly_under_reward.asset_infos[1].value
              }}
            </span>
          </div>
          <div class="data">
            <i>{{ langTxt[lang].recommend.myTeam[2] }}</i>
            <span class="ico silver">{{
              item.total_reward.asset_infos[0].type == assetType
              ? item.total_reward.asset_infos[0].value
              : item.total_reward.asset_infos[1].value
            }}</span>
          </div>
          <div class="data">
            <i>{{ langTxt[lang].recommend.myTeam[3] }}</i>
            <span>{{ item.directly_under_count }}</span>
          </div>
          <div class="status">
            <span class="mortina" v-if="item.directly_under_count == 0 && item.total_count == 0">{{ langTxt[lang].recommend.myTeam[6] }}</span>
            <span class="mortina agent" v-else v-html="'Commision Based Agent'"></span>
          </div>
        </li>
      </ul>
      <ui_page_btn :dataLength="memberList.length" :count="count" :offset="offset" @turnPage="getPromotionTeamMembers">
      </ui_page_btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.null {
  font-size: 0.18rem;
  text-align: center;
  color: #fff;
  opacity: 0.5;
}

dl {
  margin: 0;
}

dl.ul dt {
  span {
    text-align: left;
  }
}

dd,
dt {
  span {
    width: 2rem;
    box-sizing: border-box;
  }
}

dd {
  span {
    height: 0.35rem;
    line-height: 0.35rem;
  }

  .mortina {
    font-size: 0.18rem;
    color: #efdb9f;
  }

  .agent {
    color: rgb(139, 222, 178);
    line-height: 1;
  }
}
</style>
<script>
import axios from "axios";
import { defineComponent, onMounted, ref, computed } from "vue";
import common from "../../utils/common";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_page_btn from "@/components/ui/ui_page_btn";

export default defineComponent({
  name: "my_team",
  props: {},
  components: { ui_page_btn },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    // 推广奖励币种
    const assetType = 3;
    // 获取推广团队的成员信息
    let memberList = ref([]);
    let offset = ref(0);
    let count = ref(20);
    const getPromotionTeamMembers = (pageIndex) => {
      offset.value = pageIndex;
      const sendUrl =
        common.userApi.requestUrl +
        "/misc_api/promotion_team_members?offset=" +
        offset.value * count.value +
        "&count=" +
        count.value;
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getPromotionTeamMembers");
      axios
        .get(sendUrl, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb', r);
          if (r.data.code == 0) {
            memberList.value = r.data.members;
          } else {
            toastr["error"](r.data.msg);
          }
        })
        .catch((e) => {
          toastr["error"](e);
        })
        .finally(() => {
          common.hideLoading("getPromotionTeamMembers");
        });
    };
    onMounted(() => {
      getPromotionTeamMembers(0);
    });

    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;
    return {
      langTxt,
      lang, getPromotionTeamMembers, memberList, offset, count,assetType
    };
  },
});
</script>
