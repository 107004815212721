<template>
  <div class="invite">
    <dl class="data">
      <dd>
        <div class="name">{{ langTxt[lang].recommend.invite[0] }}</div>
        <div class="val">{{ userPromotionInfo.directly_under_count }}</div>
      </dd>
      <dd>
        <div class="name">{{ langTxt[lang].recommend.invite[1] }}</div>
        <div class="val">{{ userPromotionInfo.total_count }}</div>
      </dd>
      <dd>
        <div class="name">{{ langTxt[lang].recommend.invite[2] }}</div>
        <div class="val">{{ userPromotionInfo.today_new_count }}</div>
      </dd>
      <dd>
        <div class="name">{{ langTxt[lang].recommend.invite[3] }}</div>
        <div class="val">{{ userPromotionInfo.today_active_count }}</div>
      </dd>
    </dl>
    <div class="link">
      <div class="info">
        <div class="ico">
          <img :src="cdnUrl + '/web_assets/img/freetojoin.png'" alt="" />
        </div>
        <h1>{{ langTxt[lang].recommend.invite[4] }}</h1>
        <p>
          {{ langTxt[lang].recommend.invite[5] }}
        </p>
      </div>
      <div class="input onlypc">
        <input type="text" disabled v-model="linkUrl" />
        <ui_btn_1 id="copy" :width="'0.68rem'" @click="copyLinks">{{
          langTxt[lang].recommend.invite[6]
        }}</ui_btn_1>
      </div>
      <div class="qrc onlymobile">
        <QrcodeVue :value="linkUrl" :size="256" level="H" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.invite {
  max-width: 12rem;
  margin: 0.6rem auto;

  .data,
  .link {
    background: #2a2958;
    border: 1px solid #38366c;
    border-radius: 20px;
  }

  .data {
    padding: 0.3rem 0;
    margin-bottom: 0.2rem;
    display: flex;
    text-align: center;
    color: #fff;
    justify-content: center;
    align-items: center;

    dd {
      margin: 0;
      width: 2rem;
      height: 1rem;
      padding: 0 0.3rem;
    }

    .name {
      font-size: 0.2rem;
      line-height: 0.3rem;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 0.1rem;
    }

    .val {
      font-size: 0.3rem;
      font-weight: bold;
      line-height: 0.42rem;
      background: linear-gradient(83.73deg, #ff8688 0%, #e9373e 99.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      color: transparent;
    }
  }

  .link {
    min-height: 5.1rem;
    color: #fff;
    text-align: center;

    .info {
      margin-bottom: 0.6rem;

      .ico {
        margin-top: 0.4rem;
        text-align: center;
      }

      h1 {
        font-size: 0.3rem;
      }

      p {
        font-size: 0.2rem;
        line-height: 0.3rem;
        width: 3.6rem;
        margin: 0 auto;
        text-transform: capitalize;
      }
    }

    .input {
      width: 7.52rem;
      border: 1px solid #eb4046;
      border-radius: 0.2rem;
      margin: 0 auto;
      padding: 0.11rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      input {
        width: 6.33rem;
        background-color: transparent;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 0.2rem;
      }
    }
  }
}
</style>
<script>
import { computed, defineComponent, onMounted, ref, reactive, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import common from "../../utils/common";
import axios from "axios";
import QrcodeVue from "qrcode.vue";

export default defineComponent({
  name: "invite",
  props: {},
  components: { ui_btn_1, QrcodeVue },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    let cdnUrl = computed(() => {
      return store.state.cdnUrl;
    });
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    // 获取分享链接(支持游客)
    let linkUrl = ref("");
    const getShareLink = () => {
      if (myUid.value) {
        const sendUrl = common.userApi.requestUrl + "/user_api/share_link";
        const sendHeaders = common.buildHeaders("form");
        common.showLoading("getShareLink");
        axios
          .get(sendUrl, { headers: sendHeaders })
          .then((r) => {
            console.log(r);
            if (r.data.code == 0) {
              linkUrl.value = r.data.link;
              //copy
              // common.copyText("#copy", r.data.link);
            } else {
              toastr["error"](r.data.msg);
            }
          })
          .catch((e) => {
            toastr["error"](e);
          })
          .finally(() => {
            common.hideLoading("getShareLink");
          });
      }
    };
    const copyLinks = async () => {
      await common.toClipboard(linkUrl.value);
      toastr["success"]("Share link copied successfully, go paste it !!");
    };
    let myUid = computed(() => {
      return store.state.uid;
    });
    //登录后刷新链接
    watch(myUid, (newValue, oldValue) => {
      getShareLink();
      getUserPromotionInfo();
    });
    // 获取用户的推广信息
    // /misc_api/promotion_info
    let userPromotionInfo = reactive({
      directly_under_count: "-",
      today_active_count: "-",
      today_new_count: "-",
      total_count: "-",
    });
    const getUserPromotionInfo = () => {
      if (myUid.value) {
        const sendUrl = common.userApi.requestUrl + "/misc_api/promotion_info";
        const sendHeaders = common.buildHeaders("form");
        common.showLoading("getUserPromotionInfo");
        axios
          .get(sendUrl, { headers: sendHeaders })
          .then((r) => {
            console.log(r);
            if (r.data.code == 0) {
              userPromotionInfo.directly_under_count = r.data.info.directly_under_count;
              userPromotionInfo.today_active_count = r.data.info.today_active_count;
              userPromotionInfo.today_new_count = r.data.info.today_new_count;
              userPromotionInfo.total_count = r.data.info.total_count;
            } else {
              toastr["error"](r.data.msg);
            }
          })
          .catch((e) => {
            toastr["error"](e);
          })
          .finally(() => {
            common.hideLoading("getUserPromotionInfo");
          });
      }
    };

    onMounted(() => {
      getShareLink();
      getUserPromotionInfo();
    });
    return { cdnUrl, langTxt, lang, linkUrl, copyLinks, userPromotionInfo };
  },
});
</script>
