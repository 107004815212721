<template>
  <div class="withdrawrecord">
    <div v-if="recordList.length == 0 && offset == 0">
      <p class="null">No record of reward selection</p>
    </div>
    <div v-else>
      <dl class="ul onlypc">
        <dt>
          <div class="time">{{ langTxt[lang].recommend.record[0] }}</div>
          <div class="income">{{ langTxt[lang].recommend.record[1] }}</div>
        </dt>
        <dd v-for="(item, inx) in recordList" :key="inx">
          <div class="time">{{ formatDateTime(item.add_time) }}</div>
          <div class="income">
            <span class="ico">{{ formatAssetSilver(item.reward.asset_infos) }}</span>
          </div>
        </dd>
      </dl>
      <ul class="onlymobile">
          <li v-for="(item, inx) in recordList" :key="inx">
            <div class="data">
              <span>
                <i>{{ langTxt[lang].recommend.record[1] }}</i>
                <span class="ico">{{ formatAssetSilver(item.reward.asset_infos) }}</span>
              </span>
              <span>{{ formatDateTime(item.add_time) }}</span>
            </div>
          </li>
      </ul>
      <ui_page_btn :dataLength="recordList.length" :count="count" :offset="offset" @turnPage="getShareRewardTakeInfos">
      </ui_page_btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import common from "../../utils/common";
import ui_page_btn from "../../components/ui/ui_page_btn.vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "withdrawrecord",
  props: {},
  components: { ui_page_btn },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    let offset = ref(0);
    const count = 20;

    // 获取用户分享奖励的领取信息
    // /misc_api/share_reward_take_infos[?offset=xx&count=xx]
    let recordList = ref([]);
    const getShareRewardTakeInfos = () => {
      const sendUrl =
        common.userApi.requestUrl +
        "/misc_api/share_reward_take_infos?offset=" +
        offset.value * count +
        "&count=" +
        count;
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getShareRewardTakeInfos");
      axios
        .get(sendUrl, { headers: sendHeaders })
        .then((r) => {
          console.log('--lb',r);
          if (r.data.code == 0) {
            recordList.value = r.data.infos;
          } else {
            toastr["error"](r.data.msg);
          }
        })
        .catch((e) => {
          console.erro(e);
          toastr["error"]("e");
        })
        .finally(() => {
          common.hideLoading("getShareRewardTakeInfos");
        });
    };
    getShareRewardTakeInfos();

    //格式化时间
    const formatDateTime = (t) => {
      return common.formatDateTime(t);
    };
    //筛选金币
    const formatAssetSilver = (asset_infos) => {
      return common.formatAssetSilver(asset_infos);
    };

    return {
      langTxt,
      lang,
      offset,
      recordList,
      getShareRewardTakeInfos,
      formatDateTime,
      formatAssetSilver, count
    };
  },
});
</script>
