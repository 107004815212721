<template>
  <div class="recommend layout_left_right">
    <div class="layout">
      <div class="layout_left">
        <ui_side_menu @updateIndex="switchItem" :sideMenuList="sideMenuList"></ui_side_menu>
      </div>
      <div class="layout_right">
        <invite v-if="menuIndex == 0"></invite>
        <myTeam v-else-if="menuIndex == 1"></myTeam>
        <withdraw_rewards v-else-if="menuIndex == 2"></withdraw_rewards>
        <withdraw_record v-else-if="menuIndex == 3"></withdraw_record>
        <channel_reward v-else-if="menuIndex == 4"></channel_reward>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import ui_side_menu from "@/components/ui/ui_side_menu";
import common from "../utils/common";
import invite from "@/components/recommend/invite";
import myTeam from "@/components/recommend/myTeam";
import withdraw_rewards from "@/components/recommend/withdraw_rewards";
import withdraw_record from "@/components/recommend/withdraw_record";
import channel_reward from "@/components/recommend/channel_reward";


export default defineComponent({
  name: "recommend",
  components: {
    ui_side_menu,
    invite,
    myTeam,
    withdraw_rewards,
    withdraw_record,
    channel_reward,
  },
  setup() {
    let store = useStore();
    let isProd = common.isProduction;
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;
    const sideMenuList = computed(() => {
      return langTxt[lang.value].recommend.menu;
    });

    let menuIndex = ref(null);
    const switchItem = (inx) => {
      menuIndex.value = inx;
    };
    //监控uid。如果没有uid，显示登录框
    let uid = computed(() => {
      return store.state.uid;
    });
    if (!uid.value) {
      common.showLogin();
      menuIndex.value = 0;
    } else {
      menuIndex.value = 0;
    }
    watch(uid, (newValue, oldValue) => {
      if (!uid.value) {
        common.showLogin();
        menuIndex.value = 0;
      } else {
        menuIndex.value = 0;
      }
    });
    onMounted(() => { });
    return {
      langTxt,
      lang, isProd, switchItem, sideMenuList, menuIndex, invite
    };
  },
});
</script>
<style lang="scss">
.router_recommend {
  .recommend {
    .layout_right {
      .null {
        font-size: 0.18rem;
        text-align: center;
        color: #fff;
        opacity: 0.5;
      }
    }
  
    dd {
      .ico {
        padding-left: 0.5rem;
        height: 0.35rem;
        line-height: 0.35rem;
        background: url("https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png") no-repeat left center/ 0.3rem auto;
        &.silver{
          background-image: url('https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png');
        }
      }
    }
  }
}
@media screen and (max-width: 1059px){
  .router_recommend{
    .recommend{
      height: auto;
      display: block;
      .layout{
        display: block;
        width: 100%;
        .layout_left{
          width: auto;
          height: .88rem;
          border: 0;
          overflow: hidden;
          background-color: #1B203F;;
          .ui_slide_menu{
            display: flex;
            flex-wrap: nowrap;
            align-items: top;
            .icon_back{
              display: inline-block;
              width: .48rem;
              height: .48rem;
              flex-shrink: 0;
              flex-grow: 0;
              margin: .2rem;
            }
            ul{
              display: flex;
              flex-wrap: nowrap;
              padding: 0;
              width: auto;
              height: 2rem;
              overflow-y: hidden;
              overflow-x: auto;
              li{
                flex-shrink: 0;
                flex-grow: 0;
                border: 0;
                height: .88rem;
                span{
                  font-size: 0.32rem;
                  opacity: 1;
                  padding: 0 .2rem;
                  height: 0.88rem;
                  line-height: .88rem;
                  &.active{
                    color: #EF4F55;
                    &::after{
                      width: 0.52rem;
                      height: .08rem;
                      top: auto;
                      bottom: 0;
                      left: 50%;
                      margin-left: -0.26rem;
                    }
                  }
                }
              }
            }
          }
        }
        .layout_right{
          padding: 0;
          .invite{
            margin: 0;
            .data{
              margin: 0;
              border: 0;
              border-radius: 0;
              margin-bottom: .48rem;
              padding: 0.3rem .5rem;
              flex-wrap: wrap;
              justify-content: left;
              dd{
                text-align: left;
                width: 2.8rem;
                box-sizing: border-box;
                padding: 0;
                margin: .2rem;
                position: relative;
                .name{
                  font-size: .32rem;
                }
                .val{
                  font-size: .44rem;
                }
                &:nth-child(odd){
                  &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: .3rem;
                    width: 1px;
                    height: 0.4rem;
                    background-color: #38366C;;
                  }
                }
              }
            }
            .link{
              border-radius: 0;
              border-width: 1px 0;
              padding-bottom: .48rem;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              .info{
                .ico{
                  height: .9rem;
                  margin-bottom: .1rem;
                  img{
                    width: auto;
                    height: .9rem;
                  }
                }
                h1{
                  font-size: .36rem;
                }
                p{
                  width: 6rem;
                  font-size: .32rem;
                  text-transform: capitalize;
                  line-height: .44rem;
                }
              }
              .input{
                display: none;
              }
              .qrc{
                width: auto;
                height: auto;
                border: .08rem solid #fff;
              }
            }
          }
          .my_team,.withdrawRewards,.withdrawrecord{
            ul{
              li{
                background-color: #2A2958;
                margin: .16rem 0;
                .ico{
                  padding-right: .44rem;
                  background: url("https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png") no-repeat right center/ 0.36rem auto;
                  &.silver{
                    background-image: url('https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png');
                  }
                }
                .info,.data{
                  padding: 0 0.32rem;
                  color: #fff;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: .84rem;
                  font-size: .32rem;
                  i{
                    opacity: .5;
                    font-style: normal;
                  }
                }
                .info{
                  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
                }
                .status{
                  height: 1.2rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: .36rem;
                  border-top: 1px solid rgba(255, 255, 255, 0.05);
                  color: #efdb9f;
                  .agent{
                    color: #8bdeb2;
                  }
                }
              }
            }
          }
          .withdrawRewards{
            .info{
              .time{
                font-size: .24rem;
              }
            }
          }
          .withdrawrecord{
            ul li .data span i{
              margin-right: .2rem;
            }
          }
        }
      }
    }
  }
}
</style>
