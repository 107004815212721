<template>
  <div
    class="pages_btn disselect"
    v-if="dataLength == count || currentPage != 0"
  >
    <span class="btn prev" @click="turnPage()"
      ><i
        :class="{ dis: currentPage <= 0 }"
        @click="currentPage >= 0 ? currentPage-- : ''"
      ></i></span
    >{{ currentPage + 1
    }}<span class="btn next" @click="dataLength == count ? turnPage() : ''"
      ><i
        :class="{ dis: dataLength < count }"
        @click="dataLength == count ? currentPage++ : ''"
      ></i
    ></span>
  </div>
</template>
<style lang="scss" scoped>
.pages_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  .btn {
    display: inline-block;
    width: 0.8rem;
    height: 0.42rem;
    background: #2a2958;
    border: 1px solid #38366c;
    border-radius: 0.05rem;
    margin: 0 0.3rem;
    overflow: hidden;

    i {
      display: inline-block;
      width: 0.8rem;
      height: 0.42rem;
      cursor: pointer;
      background: url("https://cdn.blockwar.io/web_assets/img/ico_arrow_right.png") no-repeat center
        center / 0.24rem auto;

      &.dis {
        cursor: url("https://cdn.blockwar.io/web_assets/img/ico_cursor.png"), auto;
      }
    }

    &.prev {
      transform: rotate(180deg);
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.15),
          rgba(255, 255, 255, 0.15)
        ),
        #2a2958;
    }
  }
}
</style>
<script>
import { defineComponent, ref } from "vue";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_page_btn",
  props: {
    //
    dataLength: { type: Number, default: 0 },
    count: { type: Number, default: 20 },
    offset: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  emits: ["turnPage"],
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    let currentPage = ref(props.offset);
    const turnPage = () => {
      console.log("turnPage", currentPage.value);
      if (currentPage.value >= 0) {
        ctx.emit("turnPage", currentPage.value);
      } else {
        currentPage.value = 0;
      }
    };
    return { turnPage, currentPage };
  },
});
</script>
